import React, { Component } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import './App.css';
import axios from 'axios';

// Final Sample Received = LLR
// Final Sample Approved/Closed = CPL
// Concept Resubmit = GTX
// Concept Received = PLN
// Concept In Review = CUR
// Proceed to Prod = CUK
// Final Sample In Review = WUL
// Cancelled/Closed = CCL

// const baseURL = "https://wbei.my.workfront.com";
const baseURL = "https://wbei.preview.workfront.com";
// Workfront and proofHQ URLs
const wfUrls = {
  baseUrl: process.env.baseWFUrl,
  baseNuxeoUrl: process.env.baseNuxeoUrl,

  getIssuePath: '/attask/api/v12.0/opTask/search?projectID={projectID}&fields=*,parameterValues',
  getProjectAndTasks: '/attask/api/v12.0/project/{projectID}?fields=tasks:parameterValues,tasks:*,*,parameterValues',
  updateTask: '/attask/api/v12.0/task/{taskID}',
  updateIssue: '/attask/api/v12.0/issue/{issueID}',
  updateProject: '/attask/api/v12.0/project/{projectID}',
  nuxeoProjectQuery: "/api/v1/search/lang/NXQL/execute?query=SELECT * FROM Document WHERE submission:workfrontProjectJobId = '{jobID}' AND ecm:path startswith '/default-domain/workspaces/Submission'",
  getNuxeoDocument: "/api/v1/id/{docID}",
  nuxeoDocumentEnrichment: process.env.nuxeoDocEnrichment,
  createNuxeoProject: '/api/v1/id/{docID}',
  updateNuxeoProject: '/api/v1/id/{docID}',
  viewWorkfrontProject: '/project/view?ID={projectID}',
  getParForm: '/ui/wbcp-user/parForm.html?jobId={jobID}&issueId={issueID}',
  searchProject: '/attask/api/v12.0/project/search?name={jobID}&name_Mod=contains',
  //UPC validation related URL
  getParFormLink:'/ui/wbcp-user/parForm.html?jobId={projectID}',
  upcValidationApiUrl:'https://9xxmb3682i.execute-api.us-west-2.amazonaws.com/Stage1/validateUPC',
  getUPCUrl:'/attask/api/v9.0/PROJ/{projectID}?fields=["DE:UPC, if available"]'
};

const urlParams = new URLSearchParams(window.location.search);
const sessionID = urlParams.get('s');
const userID = urlParams.get('userID');



class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isFetched: false,
      ownedProjects: []
    }
  }

  async componentDidMount() {
    console.log('sessionID', sessionID);
    console.log('userID', userID);
    await axios.get(baseURL+'/attask/api/v12.0/user/'+userID+'?sessionID='+sessionID)
    .then((res) => {
      console.log('hi',res);
      
      this.setState({
        user: res.data,
        isFetched: true
      },() => console.log(res.data.data));
      this.getProjects();
    })
    .catch((err) => {
      console.log(err);
    });
  }

  getProjects = () => {
    // axios.get(baseURL+'/attask/api/v12.0/project/search?ownerID='+this.state.user.data.ID+'&sessionID='+sessionID+'&fields=tasks:*,*,parameterValues,opTasks')
    axios.get(baseURL+'/attask/api/v12.0/project/search?ownerID='+this.state.user.data.ID+'&sessionID='+sessionID+'&fields=*,opTasks')
      .then((res) => {
        console.log(res.data.data);
        this.setState({
          ownedProjects: res.data.data

        });
      })
      .catch((err) => {
        console.log(err);
      });
  }

  statusToComplete = (pid) => {
    axios.put(`${baseURL}/attask/api/v12.0/project/${pid}/status=CPL?sessionID=${sessionID}`)
      .then((res) => {
        console.log(res);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  render() {
    const { user, isFetched } = this.state;
    
    if (!isFetched) {
      return <div> Loading ... </div>
    }

    return (
      <>
        <Container>
          <Row>
          <Col className="userInfo">
            <strong>{user.data.name}</strong> - &nbsp;{user.data.title}<br/>
            <strong>E-mail Address: </strong>{user.data.emailAddr}<br/><br/><br/>
          </Col>
          </Row>
        </Container>
        <Container>
            {
              this.state.ownedProjects.map((value,key) => 
                (
                  <>
                  <Row>
                    <Col><h5>{value.name}</h5></Col><Col><p>{value.status}</p></Col>
                  </Row>
                  <Row>
                    <Col>
                      <a href={`${baseURL}/project/${value.ID}`} target="_blank">
                        Go to Project
                      </a>
                    </Col>
                    <Col>
                      <button onClick={() => this.statusToComplete(value.ID)}>Close</button>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      Open Issues: {value.numberOpenOpTasks}
                    </Col>
                  </Row>
                  <br/>
                  </>
                )
              )
            }
        </Container>
      </>
      );
    }
}

export default App;
